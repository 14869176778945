.dialPad {
  position: fixed;
  bottom: 0%;
  right: 5%;
  transform: translateX(-50%) translateY(100%);
  max-height: 700px;
  max-width: 450px;
  width: 350px;
  height: 480px;
  background-color: #1d2031;
  transition: transform 0.3s ease-in-out;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  align-items: center;
  z-index: 61;
}

.dialPad.visible {
  transform: translateX(-50%) translateY(0);
  z-index: 61;
  /* display: block;
  position: absolute; */
}
.dialPadContainer {
  /* height: 100%; */
}
.staticDialpad {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: #1d2031;
  align-items: center;
  height: 100vh;
}

.statusBar {
  background: #2d2b41;
  width: 100%;
  height: 45px;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}

.profilePic {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
}

.profilePic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.dialPadSelectors > .ant-select > .ant-select-selector {
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 700;
}
.agentStatusForWhiteBackground > .ant-select > .ant-select-selector {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

/* .dialpadSelectors > .ant-select .ant-select-arrow {
  font-size: 16px;
  color: white;
} */
.agentStatusForWhiteBackground > .ant-select > .ant-select-selector > .ant-select-selection-item {
  color: black;
  font-size: 14px;
  font-weight: 400;
}
.dialPadSelectors > .ant-select > .ant-select-selector > .ant-select-selection-item {
  color: white;
}
.agentStatusDropDownIcon {
  background-position: 63% 7.5%;
  background-size: 1000px 1000px;
}
.voiceHeaderDiv {
  width: 1380px;
  display: flex;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #e6e7ec;
  font-family: "Inter", sans-serif;
  justify-content: space-between;
  background-color: "#596c9412" !important;
}
.voiceHeaderText {
  font-size: 26px;
  align-items: center;
  padding-bottom: 5px;
  margin-left: 35px;
  font-weight: 600;
  line-height: 18px;
}
.voiceHeaderContainer {
  padding-top: 0%;
  margin-top: 0%;
  display: flex;
  flex-direction: column;
}
/* .breadcrumbs {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  margin-left: 10px;
  /* color: #010101; */
/* font-size: 25px; */

/* font-weight: 300; */
/* } */
.breadcrumbs > .ant-breadcrumb {
  /* background-color: #010101; */
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}
.voiceHomeIcon {
  background-image: url("../icons/helloSendIcons.png");
  height: 33px;
  width: 40px;
  background-size: 1650px 729px;
  background-position: 20% 88.4%;
  margin-left: 33px;
}
.voice-search-contact-select-container {
  background-color: #2a2b3d !important;
  color: #cccccc !important;
  border-radius: 8px !important;
}

.voice-search-contact-select-container:focus-within {
  border: 1px solid #605bff !important;
}

.incoming-popup-configured-number {
  background-color: #2a2b3d !important;
  color: #cccccc !important;
  border-radius: 8px !important;
}
.voice-search-contact-select-container .ant-select-selector {
  border: none !important;
  background-color: #2a2b3d !important;
}

.voice-dialpad-select-number .ant-select-selector {
  background-color: #2a2b3d !important;
  color: #cccccc !important;
}
.voice-dialpad-select-number .ant-select-selection-search-input,
.voice-dialpad-select-number .ant-select-selection-placeholder {
  color: #cccccc !important;
}

.line-height-contact-card {
  line-height: 3;
}

.dialCloseIcon {
  width: 23px;
  height: 17px;
  background-size: 800px 800px;
  background-position: 32% 71.6%;
  cursor: pointer;
  background-image: url("../icons/Action\ icons.svg");
}

.disable-minimize {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
.redirect-pageIcon-pd {
  margin-left: auto;
}

.fromNumbersList {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34374a;
  height: 48px;
  border-radius: 8px;
}

.dialpad-keys > .row {
  display: flex;
  justify-content: center;
}

.dialpad-keys > .row > .col {
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px !important;
  width: 50px !important;
  margin: 3%;
  background-color: #34374a;
  border-radius: 50%;
  font-size: 13px;
  text-align: center;
  margin-bottom: 0%;
}

#dialpadLetters {
  font-size: 8px;
  letter-spacing: 2px;
}
.enteredNumbers {
  font-size: 20px;
  font-weight: bolder;
  color: white;
}

.answered .dialpad-keys .row .col {
  height: 45px !important;
  width: 45px !important;
}
.answered #dialpadLetters {
  font-size: 7px !important;
  letter-spacing: 1px !important;
}
.answered .enteredNumbers {
  font-size: 15px !important;
  letter-spacing: 1px !important;
}

.dialpad-actions {
  display: flex;
  justify-content: space-around;
}

.fromNumbersList > .agentStatusForWhiteBackground > .ant-select > .ant-select-selector {
  font-size: 19px;
  font-weight: 200;
}

.dialpadSelectList .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: normal;
  background-color: transparent;
}

.dialpadSelectList.ant-select-dropdown {
  z-index: 4000;
}
.dialPadSelectors
  > .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border: none;
}
.agentStatusForWhiteBackground
  > .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border: none;
}
.hsLandlineIcon {
  background-image: url("../icons/Cards.svg");
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-position: 52% 1%;
  background-size: 640px 749px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #0000001f;
  box-shadow: 0px 20px 20px 0px #0000001f;
  cursor: pointer;
}
/* .clockIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 29px;
  width: 29px;
  background-position: 38% 7%;
  cursor: pointer;
} */
.callIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 72px;
  width: 72px;
  background-position: 63% 23.4%;
  cursor: pointer;
  background-size: 875px 875px;
}
.clear-Icon {
  background-image: url("../icons/Action\ icons.svg");
  height: 25px;
  width: 37px;
  background-position: 62% 35%;
  cursor: pointer;
  background-size: 550px 550px;
}
.mute-Icon {
  background-image: url("../icons/Action\ icons.svg");
  height: 63px;
  width: 60px;
  background-position: 2% 23%;
}
.key-Pad-Icon {
  background-image: url("../icons/Action\ icons.svg");
  height: 63px;
  width: 60px;
  background-position: 14% 23%;
}

.recordIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 63px;
  width: 60px;
  background-size: 800px 800px;
  background-position: 41% 24%;
}
.onRecordIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 63px;
  width: 60px;
  background-size: 800px 800px;
  background-position: 41% 34%;
}
.dialPadActionIcons {
  background-color: #383a51;
  border-radius: 44px;
}
.on-Mute-Icon {
  background-image: url("../icons/Action\ icons.svg");
  height: 63px;
  width: 60px;
  background-position: 2% 34%;
}
.on-Key-Pad-Icon {
  background-image: url("../icons/Action\ icons.svg");
  height: 63px;
  width: 62px;
  background-position: 14% 34%;
}

.notes-save-button {
  background-color: var(--hs-violet) !important;
  width: 150px;
  height: 44px;
  border-radius: 10px !important;
  border: none;
}
.mute-text {
  color: #fa3532;
  font-size: 12px;
  font-weight: 500;
}
.call-actions-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hold-text {
  color: #fb784e;
  font-size: 12px;
  font-weight: 500;
}
.phoneIcon {
  background-position: 36% 11.7%;
  background-size: 900px 900px;
}
.call-history-call-inbound-icon {
  background-position: 86% 45%;
}
.call-history-call-outbound-icon {
  background-position: 42.6% 7%;
}
.call-history-date-icon {
  background-position: 96% 45%;
}
.call-history-duration-icon {
  background-position: 91.2% 45%;
}

.statusOpenArrow {
  background-position: 57.5% 10.8%;
  background-size: 600px 600px;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.call-menu.ant-menu-light,
.call-menu .ant-menu-title-content {
  color: #9ca3af !important;
  background-color: inherit !important;
}
.call-menu {
  border-bottom: 2px solid #374151;
}
.call-menu .ant-menu-item-selected > .ant-menu-title-content {
  color: #fff !important;
}
.call-menu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-width: 2px !important;
  border-bottom-color: #605bff !important;
}
.call-menu .ant-menu-item:hover {
  color: inherit !important;
}

.from-number-call-icon {
  background-position: 84% 13%;
  height: 28px !important;
  width: 35px !important;
  background-size: 800px 800px;
}
.from-number-call-icon-dialpad-avatar-menu {
  background-size: 700px 700px;
  background-position: 85% 13%;
  height: 28px !important;
  width: 25px !important;
}
.view-contact-details-forward-icon {
  background-position: 83.5% 16.9%;
  width: 9px !important;
  height: 15px !important;
  background-size: 500px 500px;
}
.dialpad-customer-search-icon {
  background-position: 83.8% 25%;
  height: 20px !important;
  width: 17px !important;
  background-size: 500px 500px;
}
.dialpad-customer-search-icon-for-info-message {
  background-position: 84.5% 25%;
  height: 25px !important;
  width: 26px !important;
  background-size: 700px 700px;
}
.dialpad-customer-search-click-to-call-icon {
  background-position: 74.9% 34.4%;
  height: 30px !important;
  width: 40px !important;
  background-size: 650px 651px;
}
.grey-click-to-call-icon {
  background-position: 70.5% 33.8%;
  height: 32px !important;
  width: 37px !important;
  background-size: 650px 651px;
}
.search-customer-truncate-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Ensure it respects the width of the parent element */
}
.contact-record-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Ensure it respects the width of the parent element */
}

.contact-record-text-link {
  cursor: pointer !important;
  color: #1e90ff !important;
  text-underline-offset: 5px;
  text-decoration: underline;
}
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}
.view-record-link,
.view-record-link-md {
  color: #9ca3af !important;
  cursor: pointer;
}

.view-record-link-md:hover,
.create-record-link:hover {
  color: var(--hs-violet) !important;
  transition: transform 0.5s ease-in-out;
  transform: translateX(1%) translateY(0%);
  font-size: 15px !important;
  cursor: pointer;
}

.create-record-link {
  color: #9ca3af !important;
  cursor: pointer;
  color: var(--hs-violet) !important;
}
.view-record-link:hover {
  color: var(--hs-violet) !important;
  transition: transform 0.5s ease-in-out;
  transform: translateX(1%) translateY(0%);
  font-size: 11px !important;
  cursor: pointer;
}

.onCallScreen .ant-skeleton-image {
  width: 50px !important;
  height: 50px !important;
}

.onCallScreen .ant-skeleton-image-svg {
  width: 20px !important;
  height: 20px !important;
}

.customer-in-offline-indicator {
  background-position: 13.5% 59.8%;
  height: 60px !important;
  width: 54px !important;
  background-size: 950px 951px;
}
.voice-restrict-access-indicator {
  background-position: 28% 59%;
  height: 60px !important;
  width: 54px !important;
  background-size: 950px 951px;
}

.customer-in-offline-message {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 28px;
}

.customer-in-offline-message-secondary {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 24px;
  color: #6b7280;
  text-align: center;
}

.search-number-info-text {
  color: #9ca3af;
  text-align: center;
  max-width: 250px;
}
.call-Timer.ant-statistic .ant-statistic-content {
  color: var(--hs-plain-white);
  font-size: 14px;
}

.call-record-search-icon {
  height: 32px !important;
  width: 10% !important;
}

.call-textarea {
  height: 100%;
}

.call-textarea > .ant-form-item {
  height: 100%;
}

.call-textarea > .ant-form-item > .ant-row {
  height: 100%;
}

.call-textarea > .ant-form-item > .ant-row > .ant-col {
  height: 100%;
}

.call-textarea > .ant-form-item > .ant-row > .ant-col > .ant-form-item-control-input {
  height: 100%;
}

.call-textarea > .ant-form-item > .ant-row > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content {
  height: 100% !important;
}

.customer-in-busy-indicator {
  background-position: 42.5% 58.8%;
  height: 60px !important;
  width: 62px !important;
  background-size: 950px 951px;
}
.back-to-call-arrow-icon {
  background-position: 35% 72%;
}
.back-to-channels-icon {
  background-position: 90.5% 57%;
}
.call-history-details-content {
  overflow: auto !important;
}

.dial-pad-hello-send-logo {
  background-position: 9.1% 91.4%;
  background-size: 600px 600px;
  width: 160px !important;
}
.dialpad-avatar-class {
  background-color: #d5fce3;
  color: black;
  vertical-align: middle;
  border: 1px solid #d5fce3;
  width: 35px;
  height: 35px;
}
.dialpad-agent-status-positioned-circle {
  position: absolute;
  left: 75%;
  transform: translateX(-50%);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid white;
  top: 67%;
}
.call-record-dial-input.ant-select-single.ant-select-open .ant-select-selection-item {
  color: var(--hs-plain-white) !important;
}
