.dndflow {
  /* flex-direction: column; */
  display: flex;
  flex-grow: 1;

  position: relative;
}

.dndflow aside {
  border: 2px solid #ebeced;
  padding: 13px 10px;
  font-size: 12px;
  background: #fcfcfc;
  width: 17%;
  height: 250px;
  border-radius: 12px;
  position: absolute;
  z-index: 10;
  overflow: auto;
}
.dndflow .flowFooter {
  background-color: #ffffff;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndnode {
  height: 48px;
  z-index: -1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}
.dndnode:hover {
  background-color: rgb(204, 203, 203);
}
/* .dndflow .dndnode.input {
  border-color: #0041d0;
} */

/* .dndflow .dndnode.output {
  border-color: #ff0072;
} */

.dndflow .reactflow-wrapper {
  flex-grow: 1;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  /* .dndflow aside {
      width: 20%;
      max-width: 250px;
    } */
}
/* .dndnode.dragging {
  opacity: 0.5;
  transform: rotate(3deg);
  border: 2px dashed #000;
} */

/* .dndnode .dropping {
  z-index: 100 !important;
  position: relative;
  background-color: #0041d0;
} */
.PlayIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 32px;
  width: 31px;
  /* background-color: aliceblue; */
  background-position: 10% 7%;
  background-size: 750px 750px;
}
.dragIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 27px;
  width: 29px;
  background-position: 27% 7%;
  background-size: 700px 800px;
}
.nodeMailIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 39px;
  width: 32px;
  background-position: 53% 2%;
  background-size: 750px 750px;
}
.nodeRingIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 30px;
  width: 30px;
  background-position: 14% 7%;
  background-size: 750px 750px;
}
.nodeRecordingIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 30px;
  width: 30px;
  background-position: 85.5% 3%;
  background-size: 750px 750px;
}
.nodeHangupIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 30px;
  width: 38px;
  background-position: 90.5% 3%;
  background-size: 752px 756px;
}
.zoom-level-panel {
  position: absolute;
  top: 10px; /* Adjust the position as needed */
  right: 10px; /* Adjust the position as needed */

  z-index: 10; /* Ensure it's above other elements */
}
