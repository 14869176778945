#addCustomModuleBtn {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 2rem;

  border: none;
  background-color: #f9f9fb;
  color: #009efd;
}
