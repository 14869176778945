.tour-status-card {
  width: 300px; /* Adjust to desired width */
  height: 200px; /* Adjust to desired height */
  overflow-y: auto; /* Allows scrolling if content overflows */
  font-size: 16px;
  margin-right: 100px; /* Adjust text size if needed */
}
.voice-tour.ant-tour {
  /* width: 320px !important; */
}
