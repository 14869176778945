.guide {
  /* Styles for the paragraph */
  margin-bottom: 20px; /* Adjust margin as needed */
}

.card {
  /* Styles for the card */
  background-color: #ffffff;
  box-shadow: 0px 4px 30px #6363630f;
  height: 187px;
  width: 224px;
  border: 1px solid #ccc; /* Example border */
  border-radius: 16px;
}
.card.ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}
.top-newSign-bar {
  background-color: #605bff;
  color: #fff;
  height: 50px;
  width: 100%;
  outline: 3px solid #605bff;
}
.newSign-buyNumberBtn {
  background-color: #fff;
  font-weight: 700;
  color: #605bff;
  margin-left: 1rem;
}

.goToServiceBtn {
  outline: none;
  position: relative;
  margin: auto;
  padding: 9px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
}

.goToServiceBtn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #f3f3ff;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.goToServiceBtn span {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03em;
  color: #605bff;
  white-space: nowrap;
}

.goToServiceBtn svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #605bff;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.goToServiceBtn:hover:before {
  width: 100%;
  background: #f3f3ff;
}

.goToServiceBtn:hover svg {
  transform: translateX(0);
}

.goToServiceBtn:active {
  transform: scale(0.95);
}

@keyframes pulse1 {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    opacity: 0;
    transform: scaleX(1.4) scaleY(1.6);
  }
  100% {
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.finish-modal.ant-modal .ant-modal-content {
  padding: 0px !important;
}
.finish-modal-left {
  border-radius: 8px 0px 0px 8px;
  min-height: 475px;
  background: url(../../images/bg-image.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.finish-modal-right {
  background-color: var(--hs-dark-blue);
  border-radius: 0px 8px 8px 0px;
  min-height: 475px;
}
.gotoLeftService-btn {
  background-color: #fff;
  color: var(--hs-violet);
  height: 60px;
  min-width: 160px;
  border-radius: 37px;
  border: 0px;
}
.gotoLeftService-btn:hover {
  color: var(--hs-violet) !important;
}
.finish-modal-content {
  color: #fff;
  display: flex;
  margin-top: 30% !important;
  justify-content: center;
  flex-flow: column;
}
