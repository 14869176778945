#otpModalIconDiv {
  display: block;
  text-align: center;
}

#otpModalIconDiv > img {
  height: 5rem;
  margin-top: 0.5rem;
}

#otpModalIcon {
  font-size: 5rem !important;
}

.otpModal {
  border-radius: 300px !important;
  background-color: #ffffff !important;
  height: 285px !important;
  width: 465px !important;
}

#otpModal {
  display: block;
  align-items: center;
  text-align: center;
}

.otpModal .ant-modal-content {
  border: none;
  border-radius: 45px !important;
}

#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  outline: none;
}

#otpInput {
  border: 0px;
  border-bottom: 2px solid rgb(212, 212, 212);
  width: 50px;
  margin: 10px;
  padding-left: 10px;
  background: transparent;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 0%;
}

#otpInput:focus-visible {
  outline: 0px;
}

#otpEmailInput {
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 5px 5px;
  width: 100%;
  margin: 10px;
  height: 50px;
}

#otpEmailInput .ant-input-search {
  border: none !important;
}

#otpEmailInput .ant-input-search-button {
  border-radius: 20px;
  background-color: #605bff;
  box-shadow: 1px 1px 18px -5px #605bff;
  font-size: 12px !important;
  width: 100px;
  font-weight: bolder !important;
}

#otpEmailInput .ant-input-search-input {
  border-radius: 20px 0 0 20px;
}

#otpEmailInput .ant-input-group-addon:last-child {
  background: transparent;
}

#otpEmailInput .ant-input.ant-input-lg.ant-input-borderless {
  font-size: 13px;
}

.commonStyleHeading {
  margin-top: 1.5rem;
  font-size: large;
  font-weight: bolder;
  margin-bottom: 0.5rem;
}
.commonStyleMessage {
  font-size: small;
  opacity: 50%;
}

@keyframes blink {
  0% {
    border-bottom-color: #605bff;
  }
  50% {
    border-bottom-color: transparent;
  }
  100% {
    border-bottom-color: #605bff;
  }
}

.blink {
  animation: blink 1s step-start infinite;
}
