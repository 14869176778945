.schedule-check-box > * > .ant-checkbox-inner {
  border-radius: 50% !important;
}

.calendarIcon {
  min-width: 23px !important;
  background-position: 18% 19.5%;
}

.clockIcon {
  width: 45px;
  background-position: 10.8% 19.4%;
}

.datePickerPanel
  > .ant-picker-panel-container
  > .ant-picker-panel-layout
  > .ant-picker-panel
  > .ant-picker-date-panel
  > .ant-picker-header
  > .ant-picker-header-super-prev-btn,
.datePickerPanel
  > .ant-picker-panel-container
  > .ant-picker-panel-layout
  > .ant-picker-panel
  > .ant-picker-date-panel
  > .ant-picker-header
  > .ant-picker-header-super-next-btn {
  display: none;
}

.datePickerPanel > .ant-picker-panel-container > .ant-picker-panel-layout > .ant-picker-panel > .ant-picker-date-panel > .ant-picker-header {
  padding-left: 45px;
  padding-right: 45px;
}

.bgLightWhite {
  background-color: #f7f7f8;
}

.testModal > .ant-modal-content {
  border-radius: 15px;
}

.testModalCloseIcon {
  background-position: 20.7% 13.6%;
  background-color: #f7f7f8;
  border-radius: 50%;
  background-size: 52rem;
  height: 37px;
  width: 37px;
  top: -3px;
}

.previewCls {
  font-size: 14px;
  text-wrap: wrap;
}

.dropDownLoading {
  background-position: 11.5% 27.5%;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lg-button {
  height: 47px !important;
}

.med-button {
  height: 43px !important;
}

.campaignContentContainer {
  width: 75%;
  margin-inline-start: 5%;
}
@media (max-width: 992px) {
  .campaignContentContainer {
    width: 90%;
    margin-inline-start: 5%;
  }
}

.campaignContentContainerShrinkView {
  width: 90%;
  margin-inline-start: 5%;
}

.csvImportIcon {
  background-position: 54.2% 19.3%;
}

.csvUploadIcon {
  background-position: 38.7% 26.3%;
  height: 62px !important;
  width: 35px !important;
}

.sampleCsvDownloadIcon {
  background-position: 59.2% 19.3%;
}

.importCSVModal {
  width: 650px !important;
}

.csvDragger > .ant-upload-drag,
.csvDragger:hover > .ant-upload-drag {
  background-color: var(--hs-light-blue);
  border: 1px dashed;
  border-color: var(--hs-violet) !important;
}

.csvError > .ant-upload-drag,
.csvError:hover > .ant-upload-drag {
  background-color: var(--hs-light-red);
  border: 1px dashed;
  border-color: var(--hs-red) !important;
}

.closeIcon {
  height: 17px !important;
  width: 17px !important;
  background-position: 16% 1.45%;
  background-size: 30rem;
}

.schedule-check-box > .ant-checkbox-checked > .ant-checkbox-inner,
.schedule-check-box:hover > .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: var(--hs-violet) !important;
}

.timePickerPopupPanel .ant-picker-ok > button {
  background-color: var(--hs-violet) !important;
}
.timePickerPopupPanel.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: var(--hs-light-violet);
}
.timePickerPopupPanel .ant-picker-now-btn {
  color: var(--hs-violet);
}
.csvErrorDescription {
  color: var(--hs-red);
}

.pausedStatus {
  color: var(--hs-yellow);
  background-color: var(--hs-light-yellow);
}

.stoppedStatus {
  color: var(--hs-pink);
  background-color: var(--hs-light-pink);
}

.forceStoppedStatus {
  color: var(--hs-red);
  background-color: var(--hs-v-light-red);
}

.iconContainer {
  width: 70px;
}

.ant-select-item-option-content .marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-select-item-option-content .marquee-option {
  display: inline-block;
  width: 200px;
  animation: none;
}

@media (max-width: 480px) {
  .ant-select-item-option-content .marquee-container.active .marquee-option {
    animation: marquee 5s linear infinite;
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.moduleSelectPopup {
  box-shadow: 0px 0px 20px 4px rgba(191, 191, 191, 0.25);
  z-index: 999;
}
.metric-content-text {
  font-size: 12px !important;
}
@media (min-width: 480px) {
  .metric-content-text {
    font-size: 15px !important;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
