.onboarding-sms-icon {
  background-position: 15.3% 68%;
  background-size: 800px 800px;
}
.onboarding-call-icon {
  background-position: 35.5% 2.5%;
  background-size: 1000px 1000px;
  height: 31px !important;
}
.onboarding-whatsapp-icon {
  background-position: 17.4% 82.5%;
  background-size: 800px 800px;
  height: 30px !important;
}
.onboardingDescription {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5714285714285714;
}
.onBoardingTitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 18px;
}
.onboarding-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.onboarding-list.ant-list-split .ant-list-item {
  border-block-end: 2px solid rgba(5, 5, 5, 0.06);
  padding: 12px 28px;
}
.onboarding-list {
  max-width: 704px;
}

.onboarding-list-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}
.arrow-icon {
  background-size: 900px 900px;
  background-position: 56% 17.5%;
}
.expand-arrow {
  background-position: 49% 18%;
}
.close-arrow {
  background-position: 59% 11%;
}
.expandArrowPostion {
  margin-right: 41px;
}
.ob-service-connected-tick-icon {
  background-position: 88% 55.9%;
  background-size: 450px 450px;
  height: 20px !important;
  width: 20px !important;
}
.ant-collapse-item > .ant-collapse-header {
  /* padding: 17px 21px !important; */
}
.onboarding-collapse .ant-collapse-item > .ant-collapse-header {
  padding: 17px 43px !important;
}
.onboarding-collapse .ant-collapse {
  border-bottom: 0.1px solid rgba(3, 3, 3, 0.04) !important;
}
.provider-collapse-panel:hover {
  background-color: var(--hs-light-grey);
}
.channels-feature-list:hover {
  background-color: var(--hs-light-grey);
}
