
body {
  font-family: "Inter", sans-serif, sans-serif;
}
#guideSendSmsHead {
  color: #009efd;
  margin-top: 50px;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}
#guideSmsVideoId {
  width: 700px;
  margin-top: 30px;

  /* object-fit:fill; */
}
#guidePath {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Inter", sans-serif, sans-serif;
}
#goToPipedriveButton {
  height: 48px;
  min-width: 180px;
  border-radius: 12px;
}
.taskProgress.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.taskProgress.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #009efd;
}

#goToPipedriveButton.ant-btn-primary {
  background-color: #009efd;
}
.successInstall {
  color: #009efd;
  font-family: "Inter", sans-serif;
  margin-top: 20px;
  font-weight: 700;
}
.successInstall svg {
  vertical-align: baseline;
}
.rightArrow svg {
  vertical-align: 0em;
}
@media (max-width: 768px) {
  #guideSmsVideoId {
    width: 350px;
  }
}
.successMessage {
  background-color: #e8f5e9;
  padding: 10px;
  height: 78px;
  width: 430px;
  margin: auto;
  border: 1.5px solid #43a047;
  border-radius: 8px;
}
