.create-Team-Btn {
  font-weight: 600;
  font-size: 14px;

  height: 44px;
  min-width: 180px;
}
.plusIcon {
  width: 19px;
}
.teamsCard {
  width: 240px;
  height: 230px;
  text-align: center;
}
.teamsCardNumber {
  font-size: 16px;
  font-weight: 500;
}
.teamViewMoreButton {
  margin-top: 40px;
  border-radius: 30px;
  background-color: transparent;
  border: 1px solid #eaecee;
  height: 45px;
  width: 116px;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
}
.clickHereLink {
  color: var(--hs-violet);
  text-underline-offset: 4px;
}
.clickHereLink:hover {
  color: var(--hs-violet);
}
.modalAgentText {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #a8b0b9;
}
#modalListAvatar {
  width: 54px;
  height: 54px;
}
.modalListAgentText {
  font-size: 16px;
  font-weight: 400;
  color: #374151;
}
.modelListText {
  font-size: 17px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}
.teamsModalBox > .ant-modal-mask {
  background-color: transparent;
}

.DrawerInput1 {
  width: 100%;
  height: 58px;
  /* border: 2px solid var(--hs-violet); */
  border: none;
  border-radius: 5px;
  background-color: #f7f7f8;
}
.drawerInput2 {
  width: 300px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid black;
  background-color: #f7f7f8;
}

.addAgentsButton {
  background-color: var(--hs-violet);
  border: none;
  color: white;
  border-radius: 10px;
  height: 46px;
  width: 92px;
  font-size: 16px;
  font-weight: 600;
}

.drawerList > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item {
  border-block-end: none;
  padding: 6px;
}
#drawerListProfile {
  width: 38px;
  height: 38px;
}
.drawerListTitle {
  font-size: 16px;
  font-weight: 500;
}
.searchAgentsDiv > .ant-input:focus,
.searchAgentsDiv > .ant-input:hover {
  border: 1px solid;
  box-shadow: none;
}
/* .friendlyNameDiv > .ant-input:focus,
.friendlyNameDiv > .ant-input:hover {
  border: 2px solid #5551ff;
  box-shadow: none;
} */

.agentsSelect .ant-select-selector {
  border: 0px !important;
  /* border-color: none; */
}
.createFlowDrawer > .ant-drawer-mask {
  backdrop-filter: blur(4px);
  background-color: rgba(172, 171, 171, 0.1);
}
.createFlowDrawer > .ant-drawer-content-wrapper {
  box-shadow: none;
}

.agentsPermissionValueAll {
  padding: 0px 16px;
  line-height: 0;
  font-size: 15px;
  font-weight: 500;
  background-color: #d3e2ff;
  color: #5b93ff;
  border: 2.5px solid #5b93ff;
  border-radius: 5px;
}
.createFlowDrawerSelect .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400;
  background-color: transparent;
}

.createFlowDrawerSelect {
  width: auto;
}

.teams-permission-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  background-color: transparent;
  color: #9ca3af !important;
  font-size: inherit;
}

.callFlowTabledeleteIcon {
  background-image: url("../icons/helloSendIcons.png");
  height: 42px;
  width: 41px;
  background-position: 196% 74.8%;
  border-radius: 50%;
  background-size: 1744px 800px;
}
.deleteIconWithHoverEffect {
  background-image: url("../icons/helloSendIcons.png");
  height: 42px;
  width: 41px;
  background-position: 196% 74.8%;
  border-radius: 50%;
  background-size: 1744px 800px;
}

.deleteIconWithHoverEffect:hover {
  background-color: #ffeded;
}

.agentsPermissionValueInbound {
  border: 3px solid orangered;
  padding: 0px 0px;
}
.voice-info-icon {
  background-position: 61.1% 11.7%;
  background-size: 570px 570px;
}
.createFlowDrawer .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}
.createFlowDrawer .anticon-close svg {
  display: inline-block;
  color: red;
  background-color: #fbf0f0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 13px;
}
.emptyTeamsPageIcon {
  background-image: url("../icons/Cards.svg");
  height: 177px;
  width: 113px;
  background-position: 36% 15%;
}
.teamCardPopconfirm .ant-popover-arrow {
  display: none;
}
.teamCardPopconfirm .ant-popconfirm-message {
  margin-bottom: 0;
}
.voiceModelEditIcon {
  background-position: 76% 3%;
}
.voiceModelDeleteIcon {
  background-position: 80.2% 3%;
}
.voiceAgentsDeleteIcon {
  background-position: 76.2% 3%;
  background-size: 800px 800px;
}
.permissionDropDownIcon {
  background-position: 57% 11.3%;
  background-size: 500px 500px;
  width: 16px !important;
}
.agentsPermissionValue {
  color: #9ca3af;
  font-size: 14px;
}
.fs-14-fw-500 {
  font-size: 14px;
  font-weight: 500;
}
.kebabMenuIcon {
  background-position: 80% 9%;
}
.fs-14-fw-400 {
  font-size: 14px;
  font-weight: 400;
}
.voiceButton:hover {
  color: var(--hs-plain-white) !important;
  background-color: var(--hs-violet) !important;
}
/* new ---------------------> */
@media (min-width: 1200px) {
  .voice-team-card.ant-card {
    height: 258px !important;
    width: 386px !important;
  }
  .voice-team-card.ant-card .ant-card-body {
    height: 100% !important;
    padding: 20px !important;
  }
}
.team-members-icon {
  background-position: 87% 35%;
  height: 20px !important;
}
.dot-icon {
  margin: 2px;
  height: 10px !important;
  width: 10px !important;
  background-position: 94% 8.5%;
}

@media (min-width: 1200px) {
  .teamsModalBox .ant-modal {
    width: 1049px !important;
    height: 679px !important;
  }

  .teamsModalBox .ant-modal-content {
    height: 679px !important;
    background-color: #f9fafb !important;
  }

  .team-view-agents-modal-card.ant-card {
    width: 313px !important;
    height: 570px !important;
  }
}
.team-view-agents-modal-card.ant-card .ant-card-head {
  border-bottom: none !important;
}
.voice-teams-empty-online-agents-icon {
  background-position: 57% 46%;
  height: 30px !important;
  width: 36px !important;
}
.voice-teams-empty-busy-agents-icon {
  background-position: 44% 46%;
  height: 30px !important;
  width: 36px !important;
}
.voice-teams-empty-offline-agents-icon {
  background-position: 29% 46%;
  height: 30px !important;
  width: 36px !important;
}
.team-view-agents-modal-card .ant-card-extra {
  font-weight: 600 !important;
  font-size: 17px !important;
}
.voice-plan-switch-modal-icon {
  height: 65px !important;
  width: 69px !important;
  background-position: 71.4% 57.8%;
}

.voice-inbound-only-agents-screen-icon {
  height: 65px !important;
  width: 69px !important;
  background-position: 56.4% 58.2%;
}

.voice-inbound-only-agents-screen-inbound-icon {
  height: 65px !important;
  width: 69px !important;
  background-position: 80.4% 47.2%;
  background-size: 585px 717px;
}

.voice-inbound-only-agents-screen-outbound-icon {
  height: 65px !important;
  width: 69px !important;
  background-position: 70% 47.2%;
  background-size: 585px 717px;
}
