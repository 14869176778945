@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@500&display=swap");
.mainSideNavBar {
  padding-top: 1rem;
  font-family: "Cabin", sans-serif;
}

.menuCollapseButton {
  font-size: 1.2rem;
  position: fixed;
  left: 20px;
  top: 17px;
  transition: all 0.2s;
  z-index: 99;
}

.menuCollapseButton:hover {
  transition: all 0.2s;
  font-size: 1.3rem !important;
}
