.automation-drawer.ant-drawer-content .ant-drawer-footer {
  border-top: 0px !important;
}
.automationTable thead .ant-table-cell {
  background-color: #fff !important;
  font-size: 12px;
  font-weight: 400 !important;
}
.automationTable .ant-table-container {
  box-shadow: none;
}
.automationTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 14px 8px;
}
.automationTable.ant-table-wrapper table {
  border: none;
}
.automationTable .ant-table-content {
  padding: 1rem 2rem !important;
  min-height: 100vh !important;
}
.automationTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.automationTable.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 16px 8px;
  white-space: break-spaces;
}
.automationTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: inherit;
}
.automationTable.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.automationTable.ant-table-wrapper .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 1px solid #ededed !important;
}
.automationTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}
.automationTable .ant-table-body {
  height: 580px;
}
.webhook-divider-vertical.ant-divider-vertical {
  position: relative;
  height: 1.4em;
  border-inline-start: 1px solid grey;
}
.video-info {
  min-height: 100px;
  min-width: 24%;
  max-height: 130px;
  max-width: 130px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.video-thumbnail-2 {
  background-image: url("../svg/VideoThumbnails.svg");
  background-repeat: no-repeat;
  background-size: 567% 652% !important;
  background-position: 29% 2%;
  width: 100%;
  min-width: 100px;
  max-height: 130px;
  max-width: 100px;
  transform: translate(0%, 0%) scale(1.4);
}
.video-component-wrap {
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e6e7ec;
}
.video-info-list > span {
  margin-bottom: 0px;
  font-size: 13px;
  width: 100%;
  font-weight: 600 !important;
}
.video-info-list > ol {
  padding-left: 6px !important;
  margin-top: 4px !important;
  margin-bottom: 0px;
}
.video-info-list > ol > li {
  font-size: 11px;
  color: #666666;
}
.video-info-list {
  margin: 1rem;
}
.videoLogo-webhook {
  height: 65px !important;
  width: 65px !important;
  background-position: 30% 44%;
  transform: scale(0.6);
}
.webhook-video-modal.ant-modal .ant-modal-content {
  padding: 0px !important;
}
.webhook-video-modal.ant-modal .ant-modal-body {
  line-height: 0px !important;
}
.webhook-video-modal.ant-modal .ant-modal-close {
  top: -25px;
  right: -29px;
}
.iframe-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  height: 0;
}
.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.copy-icon-webhook.ant-typography .ant-typography-copy {
  width: 100% !important;
}
