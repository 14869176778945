.callFlowTable.ant-table-wrapper .ant-table-thead > tr > th {
  background: #fff;
  font-size: 16px;
  padding-right: 110px;
}
.callFlowTable.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none;
}
.callFlowTable .ant-table-container {
  box-shadow: none;
}
.callFlowTable.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: #ffffff;
}
.callFlowTable.ant-table-wrapper {
  .ant-table-cell {
    padding: 20px 10px;
    font-size: 15px;
    padding-right: 100px;
  }
}
.callFlowTable.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: center;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  transition: 0.2s ease;
}
.callFlowTable.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #fff;
}
.callFlowCard.ant-card .ant-card-body {
  padding: 2px;
  padding-left: 30px;
  padding-right: 30px;
}
.callFlowTable.ant-table-wrapper table {
  text-align: center !important;
}
.callFlowCard {
  max-height: 670px; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
}
.react-flow__pane {
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.callFlowTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #ffffff;
}
.editIcon {
  background-image: url("../icons/helloSendIcons.png");
  width: 30px;
  height: 30px;
  background-position: 100% 0%;
  background-size: 1550px 821px;
}
.normalBtn {
  color: var(--hs-violet);
  font-weight: 600;
  font-size: 14px;
  width: 120px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background-color: #ffffff;
}
.saveButton {
  height: 36px !important;
}
/* .saveButton:disabled {

  background-color: grey; 
  color: #cccccc;
  cursor: not-allowed; 
  opacity: 0.7;
} */
.handleVisible {
  background-color: #c2c2c2 !important;
  width: 1px !important;
  height: 1px !important;
}
.handleInvisible {
  background-color: transparent !important ;
  border: none !important;
}
.emptyCallFlowIcon {
  background-image: url("../icons/Cards.svg");
  height: 145px;
  width: 122px;
  background-position: 95% 21%;
}

.nodesDrawer.ant-drawer .ant-drawer-content-wrapper {
  position: absolute;
  z-index: -1 !important;
  max-width: 100vw;
  transition: all 0.3s;
}
.nodesDrawer.ant-drawer {
  position: fixed;
  inset: 0;
  z-index: 0;
  pointer-events: none;
}
