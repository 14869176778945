.serviceSidebar {
  background-color: #f9f9f9;
  color: rgba(195, 202, 217, 1);
  padding: 2rem 1rem !important;
  margin: 10px 0px !important;
}
.sidebarMenu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: 0px;
}
.sidebarMenu.ant-menu-light .ant-menu-item-selected {
  color: #29cc39 !important;
  background-color: rgba(41, 204, 57, 0.1);
}

.sidebarMenu.ant-menu-light .ant-menu-item {
  color: #030229;
  font-size: 13px;
}
.sidebarMenu.ant-menu-light .ant-menu-item-selected > .ant-menu-title-content > a > div > .right-arrow {
  background-image: url("../../svg/action-icons.svg");
  height: 25px;
  width: 25px;
  transform: translate(0%, 0%) scale(1.5);
  background-position: -200px -60px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.ant-menu-sub.ant-menu-item[data-menu-id="rc-menu-uuid-58559-3-workflow"] {
  padding-left: 56px !important;
}
.serviceSidebar > .ant-menu-title-content > a > div > .right-arrow {
  background-image: url("../../svg/action-icons.svg");
  height: 25px;
  width: 25px;
  transform: translate(0%, 0%) scale(1.5);
  background-position: -340px -60.7px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.sidebarMenuItemBadge {
  background-color: #605bff;
  top: 24px;
  padding: 0 4px;
  font-size: 10px;
  line-height: 16px;
}

.sendSmsSideBar .ant-menu-item {
  margin-left: 10px !important;
  padding-left: 15px !important;
  margin-inline: 15px !important;
  width: calc(100% - 30px) !important;
}
.sendSmsSideBar .ant-menu-submenu {
  margin-inline: 3px !important;
  width: calc(100% - 15px) !important;
}

.sidebar-overlay {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  width: 100vw;
  backdrop-filter: blur(2px);
}
@media (min-width: 992px) {
  .sidebar-overlay {
    position: relative !important;
    visibility: visible !important;
    opacity: 1;
    width: auto;
  }
}
.show-sidebar {
  visibility: visible;
  opacity: 1;
}
.creditsCoinIcon {
  background-position: 70.3% 67.7%;
  height: 18px !important;
  width: 18px !important;
}
.creditsBalance {
  border-radius: 4px !important;
  padding-left: 10px !important;
  height: 70px !important;
}
.upgrade-alert-modal.ant-modal .ant-modal-footer {
  display: flex;
}
.modal-upgrade-icon {
  background-position: 87% 95%;
  height: 65px !important;
  width: 65px !important;
}

.arrow-hide .ant-menu-submenu-arrow {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../svg/action-icons.svg") no-repeat center center;
  background-position: 14.7% 95%;
}

.arrow-hide .ant-menu-submenu-arrow::before {
  content: "";
  display: none;
}
.arrow-hide .ant-menu-submenu-arrow::after {
  content: "";
  display: none;
}
.messaging-icon {
  background-position: 9.5% 96.6%;
  background-size: 650px 650px;
}
