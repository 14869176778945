.pageIcons {
  background: url("../../images/helloSendIcons.png");
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-size: 111rem 53rem;
  margin: 0 auto;
  transform: translate(0%, 0%) scale(0.8);
}
.buyNumberIconSer {
  background-position: -7.2rem -15.4rem;
}
.textSmsIconSer {
  background-position: -7rem -37.6rem;
}
.instaIconSer {
  background-position: -7.2rem -22.9rem;
}
.faceBookIconSer {
  background-position: -7.2rem -30.6rem;
  height: 55px !important;
  width: 55px !important;
}
.banners-sprite-large {
  background: url("../../svg/banners.svg");
  width: 11rem;
  height: 3.9rem;
  background-repeat: no-repeat;
  background-size: 1373px 605px;
  transform: translate(0%, 0%) scale(1);
}

.banners-sprite-large.whatsapp {
  background-position: -639.67px -257.2px;
}

.twilio {
  background-position: -477.67px -181.2px;
}
.ringcentral {
  background-position: -238.67px -181.2px;
}
.clicksend {
  background-position: -363.67px -349.2px;
}
.telnyx {
  background-position: -366.67px -76.2px;
}
.plivo {
  background-position: -367.67px -166.2px;
}
.burstsms {
  background-position: -364.67px -254.2px;
}
.vonage {
  background-position: -365.67px -528.2px;
}
.hello_send {
  background-position: -177.67px -242.2px;
  height: 46px !important;
  width: 49px !important;
}
.teams_hellosend {
  background-position: -252.67px -454.2px;
  height: 46px !important;
  width: 49px !important;
}
.instagrammessenger {
  background-position: -629.67px -68.2px;
}
.facebookmessenger {
  background-position: -629.67px 20.9px;
}
.twilio_whatsapp {
  background-position: -639.67px -257.2px;
}

.whatsapp {
  background-position: -358.67px -291.2px;
  background-size: 45rem !important;
}

.twilioWhatsAppIconSer {
  background-position: 87% 37%;
  height: 55px !important;
  width: 55px !important;
}
.whatsAppIconSer {
  background-position: 87% 37%;
  height: 55px !important;
  width: 55px !important;
}
.service-card {
  text-align: center;
  padding: 30px;
  margin: 15px;
  cursor: pointer;
}
.service-card:hover {
  box-shadow: 0 20px 20px 0px rgba(0, 0, 0, 0.08), 0 0 2px 0px rgba(0, 0, 0, 0.25);
}

.hs-steps-modal > .ant-steps-item-title {
  width: 100% !important;
}
.hs-steps-modal > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon,
.hs-steps-modal > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  color: white !important;
  background-color: var(--hs-violet) !important;
}

.hs-steps-modal > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon,
.hs-steps-modal > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  color: white !important;
  border-color: transparent !important;
  background-color: var(--hs-pink) !important;
}

.hs-steps-modal.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--hs-violet);
}

.buynumberCloseButton .ant-modal-close {
  display: none;
}
