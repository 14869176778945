body {
  font-family: "Inter", sans-serif, sans-serif;
}

#numberError {
  color: #ee3248;
  padding-top: 9px;
  padding-left: 8px;
  font-size: 12px;
}
#nameError {
  opacity: 50%;
  font-size: 12px;
  padding-top: 9px;
  padding-left: 8px;
}
#nameErrorHide {
  font-size: 12px;
  color: #ee3248;
  padding-top: 9px;
  padding-left: 8px;
}
#buttonStyle {
  text-align: end;
  margin-right: 70px;
  margin-top: 40px;
}
#btn-next {
  width: 130px;
  height: 40px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border-radius: 12px;
  margin-bottom: 30px;
}

.addPhone-select.ant-select-selector {
  height: 40px;
}
.savePhoneTable thead .ant-table-cell {
  background-color: #009efd !important;
  color: white !important;
}
.savePhoneTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}
.savePhoneTable.ant-table-wrapper .ant-table-thead > tr > th {
  text-align: center;
}
.savePhoneTable .ant-table-tbody > tr > td {
  background-color: #f8f8f8;
  text-align: center;
}
.savePhoneTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #ebf4f8;
}
.tableIndex {
  width: 90%;
  margin-left: 5%;
}

.taskProgress.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.taskProgress.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #009efd;
}

.add-btn.ant-btn-primary.ant-btn-background-ghost {
  color: #009efd;
  border-color: #009efd;
  border-radius: 3px;
}
.add-btn .anticon {
  vertical-align: 0em;
}
.addPhoneMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  font-size: 15px;
}

#errorenableInbound {
  color: #ee3248;
  font-family: "Inter", sans-serif;
}
#enableInboundSwitch {
  margin-right: 10px;
}
.inboundErrorIcon svg {
  margin-bottom: 4px;
}

.existError {
  color: #009efd;
  font-size: 12px;
  padding-top: 9px;
}
.addPhoneMenu.ant-menu-light {
  background-color: inherit;
}
.noticeModal.ant-modal .ant-modal-content {
  border-radius: 2px;
}
.noticeModalButton {
  border-radius: 1px;
  height: 2rem;
  width: 9rem;
}
.checkMsgIcon.anticon svg {
  color: green;
  height: 1.5rem;
  width: 1.5rem;
}
.closeMsgIcon.anticon svg {
  color: red;
  height: 1.5rem;
  width: 1.5rem;
}
.showMessagingServiceSelect
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector,
.addPhone-select.ant-select-single.ant-select-lg:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
#phoneNumberInput,
#friendlyNameInput {
  border-radius: 3px;
}

.savePhoneTable .ant-table-thead .ant-table-cell:first-child {
  width: 50px;
}
.savePhoneTable
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px;
}

.addMsgServiceBtnDisabled {
  border-color: grey !important;
  color: gray !important;
}
