.activeNumbersTable thead .ant-table-cell {
  background-color: #fff !important;
  font-size: 12px;
  font-weight: 400 !important;
}
.activeNumbersTable .ant-table-container {
  box-shadow: none;
}
.activeNumbersTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 14px 1px;
}
.activeNumbersTable.ant-table-wrapper table {
  border: none;
}
.activeNumbersTable .ant-table-content {
  padding: 2rem;
}
.activeNumbersTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}
.activeNumbersTable.ant-table-wrapper .ant-table-thead > tr > th {
  text-align: center;
}
.activeNumbersTable .ant-table-tbody > tr > td {
  text-align: center;
  width: 3rem;
  max-width: 3rem;  
  font-family: "Inter", sans-serif;
}
.activeNumbersTable .ant-table-tbody > tr.ant-table-row:hover > td {
  color: #605bff;
  background-color: inherit;
}
.activeNextBtn {
  font-family: "Inter", sans-serif;
  height: 42px;
  width: 160px;
  border-radius: 4px;
  background-color: #109cf1;
  font-weight: 600;
}
.tenDlcLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f1f1f1;
  padding: 1em;
  padding-inline-start: 1rem;
  background-color: #fff5e3;
  color: #ff9800;
  font-size: 13px;
  outline: 3px solid #fff5e3;
  margin-bottom: 1.3rem;
}
.tenDlcLinkPending {
  border-top: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  padding-inline-start: 2rem;
  background-color: #fff5e3;
  color: #334d6e;
  height: 3rem;
  margin: 1.5em -0.75rem;
  font-size: 13px;
}
.formIcon {
  height: 2rem;
  width: 2rem;
  background-repeat: no-repeat;
  background: url("../icons/10DlcIcon.png");
  background-size: 26px 123px;
  transform: translate(0%, 0%) scale(1);
}
.formIconGrey {
  background-position: 3px 30px;
}
.formIconBlue {
  border-radius: 37px;
  display: flex;
  align-self: center;
  background-position: 3px 3px;
  background-color: #f1f5f9;
  cursor: pointer;
}
.releaseBtn {
  color: #e51a0d;
  background-color: #fdcbcb;
  border-radius: 4px;
}
.activeNumbersTable.ant-table-wrapper
  .ant-table-thead
  > tr:not(:last-child)
  > th[colspan] {
  border-bottom: 1px solid #ededed !important;
}
.ant-popover.wrapper-notify {
  position: fixed;
  width: 300px;
}
.a2pStatusTip {
  color: black !important;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .tenDlcLink {
    font-size: 10px;
    padding-inline-start: 0.3rem;
    padding-inline-end: 1rem;
  }
  .formIcon {
    padding-right: 2em;
    margin-left: 0px;
  }
  .tenDlcTour {
    width: 200px !important;
    font-size: 10px;
  }
  .tenDlcTour.ant-tour .ant-tour-inner .ant-tour-header .ant-tour-title,
  .tenDlcTour.ant-tour
    .ant-tour-inner
    .ant-tour-footer
    .ant-tour-buttons
    .ant-btn {
    font-size: 12px;
  }
}
