.errorNotify .ant-notification-notice-message {
  font-size: 12px !important ;
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  padding: 10px 20px 0px 16px;
  line-height: 1;
  margin-inline-end: 20px;
}
.errorNotify {
  border-radius: 3px;
  width: 100%;
  min-height: 60px;
  padding: 0px 0px 0px 0px !important;
}
.errorNotify .ant-notification-notice .ant-notification-notice-icon {
  font-size: 28px !important;
}
.errorNotify .ant-notification-notice-close {
  top: 6px !important;
  inset-inline-end: 5px !important;
}
.errorNotify .ant-notification-notice-description {
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
  padding: 0px 18px 3px 18px;
}
.errorNotify .anticon svg {
  color: black;
}
.errorNotify .ant-notification-notice-icon {
  height: 100% !important;
  background-color: #ffdce4;
}

.successNotify .ant-notification-notice-message {
  font-size: 12px !important ;
  font-family: "Inter", sans-serif;
  margin-inline-end: 20px;
  font-weight: 600 !important;
  padding: 10px 0px 0px 10px;
  line-height: 1;
}
.successNotify {
  border-radius: 3px;
  width: 100%;
  height: auto;
  max-height: 60px;
  padding: 0px !important;
  max-height: 70px;
}
.successNotify .ant-notification-notice .ant-notification-notice-icon {
  font-size: 28px !important;
}
.successNotify .ant-notification-notice-close {
  top: 6px !important;
  inset-inline-end: 5px !important;
}
.successNotify .ant-notification-notice-description {
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
  padding-right: 18px;
}
.successNotify .anticon svg {
  color: black;
}
.successNotify .ant-notification-notice-icon {
  padding: 12px 0px 12px 10px;
}

.successNotifyTitle .ant-notification-notice-message {
  font-size: 12px !important ;
  font-family: "Inter", sans-serif;
  margin-inline-end: 20px;
  font-weight: 600 !important;
  padding: 0.5rem 0rem 0rem 1.5rem;
  line-height: 1;
}
.successNotifyTitle {
  border-radius: 3px;
  width: 100%;
  height: 4rem;
  padding: 0px !important;
}
.successNotifyTitle .ant-notification-notice .ant-notification-notice-icon {
  font-size: 28px !important;
}
.successNotifyTitle .ant-notification-notice-close {
  top: 6px !important;
  inset-inline-end: 5px !important;
}
.successNotifyTitle .ant-notification-notice-description {
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
  padding-left: 1.5rem;
}
.successNotifyTitle .anticon svg {
  color: black;
}
.successNotifyTitle .ant-notification-notice-icon {
  border-left: 0.5rem solid #c9ebff;
  padding: 1.2rem 0rem 1.2rem 1rem;
}
.errorNotify .ant-notification-notice-close-icon {
  font-size: 15px;
}

.errorIcon {
  background-position: 2% 1%;
}

.successIcon {
  background-position: 7% 1%;
}
