.heads {
  color: #ffffff;
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
}
.zoho-btn {
  padding-top: 2px;
  height: 50px;
  width: 180px !important;
  margin: 3rem 2rem 4rem 2rem;
  color: #fff;
}
.zoho-btn:hover {
  color: #fff !important;
  background-color: #4944de !important;
}
.infoPara {
  width: 70%;
  text-align: center;
}
.zoho-crm-marketing-content {
  background-color: #605bff;
  height: 100vh;
  padding: 2rem;
  overflow: hidden;
}
.zoho-crm-marketing-content > div > h5,
.zoho-crm-marketing-content > div > p,
.zoho-crm-marketing-content > div > h3 {
  font-family: "Inter";
}
.sign-btn {
  background-color: #605bff;
  border-radius: 8px;
  height: 42px;
  color: #fff;
}
.leftService-select {
  background-color: #f7f7f8;
  height: 40px;
  border-radius: 8px;
}
.content-div {
  width: 88%;
  margin: auto;
}
.form-label {
  font-weight: 600;
  font-size: 14px;
}
@media (min-width: 1430px) {
  .zoho-connect-automation-pl {
    padding-left: 2.5em !important;
  }
  .zoho-connect-messaging-Ml {
    /* margin-left: 3.75em !important; */
  }
}
@media (min-width: 992px) {
  .zoho-connect-messaging-Ml {
    padding-left: 0.3125em !important;
  }
}
