.analyticsCard {
  height: 136px;
  margin-top: 35px;
}
.callsMetricText {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.03em;
  margin-top: 12px;
}
.callsMetricNum {
  font-size: 21px;
  margin-top: 10px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.voiceAnalytics > .ant-row > .ant-col > .ant-card > .ant-card-body {
  padding: 0;
}
.voiceAnalytics {
  width: 1160px;
  height: 382px;
  margin-left: 110px;
}
.analyticsIconTextContainer {
  display: flex;
  align-items: center;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.totalCallsIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 43px;
  width: 31px;
  display: flex;
  flex-direction: column;
  background-position: 33% 3.4%;
  background-size: 675px 814px;
}
.inboundIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 43px;
  width: 31px;
  display: flex;
  flex-direction: column;
  background-position: 38% 3.4%;
  background-size: 675px 814px;
}
.outboundIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 43px;
  width: 31px;
  display: flex;
  flex-direction: column;
  background-position: 42.5% 3.4%;
  background-size: 675px 814px;
}
/* .missedCallIcon{
  background-image: url("../icons/Action\ icons.svg");
  height: 43px;
  width: 31px;
  display: flex;
  flex-direction: column;
  background-position: 48.5% 3.4%;
  background-size: 675px 814px;
} */
.voiceMailIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 43px;
  width: 31px;
  display: flex;
  flex-direction: column;
  background-position: 54% 3.4%;
  background-size: 675px 814px;
}
.chartCard {
  padding: 30px;
}

.chartContainer {
  width: 1062px;
  height: 352px;
  position: relative;
}

.chartContainer canvas {
  width: 1133px !important;
  height: 100% !important;
  padding-right: 60px;
}
.callsMetricItem {
  display: flex;
  flex-direction: row;
}
.metricRow {
  padding: 23px 70px;
}
