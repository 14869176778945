.vPlayIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 12px;
  /* background-color: aliceblue; */
  background-position: 10% 7%;
  background-size: 450px 450px;
}
.customNode {
  width: 130px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 8px;
}
.customNode.focused {
  border: 2px solid var(--hs-violet);
}

.vRingIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 12px;
  /* background-color: aliceblue; */
  background-position: 14% 7%;
  background-size: 450px 450px;
}
.vVoiceMailIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 18px;
  background-position: 67.5% 7%;
  background-size: 450px 450px;
}

.automatedMessageNodeIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 18px;
  background-position: 71% 12%;
  background-size: 450px 450px;
}
.automatedMessageDrawerIcon {
  background-position: 67% 11.7%;
  background-size: 800px 800px;
}
.vRecordingIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 18px;
  background-position: 85% 3.2%;
  background-size: 500px 500px;
}
.vHangUpIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 18px;
  background-position: 89% 3.4%;
  background-size: 500px 500px;
}
.phoneNumberCallIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 15px;
  background-position: 22% 7%;
  background-size: 400px 400px;
}
.businessHourClockIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 16px;
  width: 18px;
  background-position: 17% 6.6%;
  background-size: 360px 360px;
}
.add-button {
  background: transparent;
  border: none;
  color: blue;
  cursor: pointer;
}

.react-flow__edge {
  overflow: visible !important;
}

.add-button-container {
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
}

.new-node {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}
.addSymbolIcon {
  background-image: url("../icons/Action\ icons.svg");
  width: 20px;
  height: 18px;
  background-position: 26.5% 7.3%;
  background-size: 600px 600px;
}
.nodeCloseIcon {
  background-image: url("../icons/Action\ icons.svg");
  width: 20px;
  height: 23px;
  background-position: 10% 11%;
  background-size: 500px 500px;
}
.nodeHandle {
  background-color: #c2c2c2;
  width: 1px;
  height: 1px;
}
.invisibleNodeHandle {
  background-color: none;
  border: none;
}

.nodesDrawer > .ant-drawer-content-wrapper {
  top: 62px;
  box-shadow: none;
  pointer-events: auto;
}
.nodesDrawer > .ant-drawer-mask {
  background-color: transparent;
  display: none !important;
}
.nodesDrawer > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-header {
  border-bottom: 2px solid rgba(5, 5, 5, 0.06);
}
.DrawerPlayIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 30px;
  width: 30px;
  background-position: 54.4% 12.2%;
  background-size: 1000px 1000px;
}
.typesOfPlayAudio.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border: 1px solid black;
  box-shadow: none;
}

.typesOfPlayAudio > .ant-select-selector > .ant-select-selection-item {
  font-size: 16px;
  font-weight: 500;
}

.typesOfPlayAudio > .ant-select-selector {
  border: none !important ;
}
.voiceSelect > .ant-select-open {
  border: 2px solid var(--hs-violet);
  border-radius: 5px;
}

.typesOfPlayAudio .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: normal;
  background-color: transparent;
}
.callDropdownIcon {
  background-position: 62% 7.4%;
  background-size: 800px 900px;
}
.speechTextArea {
  border-radius: 10px;
  border: 0.5px solid #cfd3d4;

  width: 100%;
  height: 129px;
  padding: 10px;
  resize: none;
  box-sizing: border-box;
}

.speechTextArea::placeholder {
  text-align: left;
  vertical-align: top;
}
.speechTextArea:focus {
  outline: none;
  border: 2px solid var(--hs-violet);
}
.callFlowButton {
  background-color: var(--hs-violet) !important;
  border: none;
  font-size: 16px;
  color: white;
  border-radius: 8px;
  padding: 2px 46px;
}
.callFlowButton:hover {
  color: var(--hs-plain-white) !important;
  background-color: var(--hs-violet) !important;
}
.audioUploadIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 60px;
  width: 60px;
  background-position: 76% 24%;
  background-size: 800px 900px;
}
.gragger {
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='32' ry='32' stroke='%23F7F7F8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
  /* border-radius: 32px; */
  /*Horizontal*/
  /* background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
background-position: bottom;
background-size: 3px 1px;
background-repeat: repeat-x; */
  /*Vertical*/
  /* background-image: linear-gradient(black 33%, rgba(255,255,255,0) 0%);
background-position: right;
background-size: 1px 3px;
background-repeat: repeat-y; */
}
.gragger.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 14px 0px;
}
.SearchAgentInput {
  width: 227px;
  height: 46px;
  border-radius: 10px;
  border: 1px solid black;
  background-color: #f7f7f8;
}
.friendlyNameInput {
  width: 100%;
  height: 52px;
  border: 2px solid var(--hs-violet);
  border-radius: 5px;
  background-color: #f7f7f8;
}

.inputStyle {
  border: 2px solid #cfd3d4;
  border-radius: 5px;
  background-color: #f7f7f8;
}
.inputStyle:hover {
  border: 2px solid #cfd3d4;
}
.ringIntervalInput {
  width: 150px;
  height: 45px;
  border-radius: 8px;
  background-color: #f7f7f8;
  border: none;
}
.ringIntervalInput:focus {
  border: 2px solid var(--hs-violet);
  box-shadow: none;
}
.DrawerCallIcon {
  background-position: 36.5% 12%;
  background-size: 800px 800px;
}
.music-type-select > .ant-select-selector {
  background-color: #f7f7f8 !important;
}
.phoneNUmber
  > .div
  > .rc-virtual-list
  > .rc-virtual-list-holder
   > .rc-virtual-list-holder-inner   /*  incomplete */
  > .ant-select-item {
  /* font-weight: normal;
  background-color: transparent;
  padding: 10px 12px; */
}
.DrawerHourIcon {
  background-position: 40.7% 11.7%;
  background-size: 800px 800px;
}
.ringTeam > .ant-select-selector {
  border: none !important ;
}
.voiceMailTextArea {
  border-radius: 10px;
  border: none;
  background-color: #f7f7f8;
  width: 340px;
  height: 110px;
  padding: 10px;
  resize: none;
  box-sizing: border-box;
}
.voiceMailTextArea:focus {
  border: none;
  outline: none;
}

/* CustomCheckbox.css */

/* Change the checkbox icon */
.ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 4px; /* Make the checkbox rounded */
  border: 2px solid #1890ff; /* Change border color */
  background-color: #fff; /* Change background color */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff; /* Change checked background color */
  border-color: #1890ff; /* Change checked border color */
}

/* .ant-checkbox-checked .ant-checkbox-inner::after {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../icons/Action\ icons\ \(4\).svg") no-repeat center center;
  background-size: contain;
  position: absolute;
} */

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #40a9ff; /* Change focus border color */
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2); /* Change focus shadow */
}
.addContainer {
  background-color: transparent;
  width: 200px;
  height: 70px;
  border-radius: 10px;
}
.addDiv {
  background-color: #fff;
  width: 50px;
  height: 20px;
  border: 1px solid #cbd0d9;
  border-radius: 5px;
}
.dndDiv {
  background-color: rgb(221 229 235);
  width: 200px;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #cbd0d9;
}
.nodeDeletePopconfirm .ant-popconfirm-message {
  margin-bottom: 0px;
  display: block;
}
.nodeDeletePopconfirm .ant-popover-arrow {
  display: none;
}
.nodeDeletePopconfirm .ant-popover-content {
  bottom: -60px;
  cursor: pointer;
}
.nodeDeleteIcon {
  background-position: 93.5% 75%;
  background-size: 1300px 600px;
}
.nodeDeletePopconfirm .ant-popover-inner {
  padding: 6px;
}
.hangUpNodeIcon {
  background-position: 96.5% 5.9%;
  background-size: 500px 600px;
}
.RingTeamBellIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 52px;
  width: 47px;
  background-position: 4% 46%;
  background-size: 500px 600px;
}

.custom-disabled-input:disabled {
  cursor: not-allowed;
  background-color: white;
  color: black;
}
/* .custom-disabled-input {
  border: 2px solid var(--hs-violet);
  border-radius: 7px;
  height: 40px;
} */
.custom-disabled-input {
  border: none !important;
}
.custom-disabled-input:hover {
  border: 1px solid !important;
}
/* .custom-disabled-input:focus {
  border: 1px solid var(--hs-violet) !important;
} */

.nodesBorder {
  border: 1.5px solid var(--hs-violet) !important;
  border-radius: 7px;
}

/* CALL RECORDING */

.recording-check-box > * > .ant-checkbox-inner {
  border-radius: 50% !important;
}
.recording-check-box > .ant-checkbox-checked > .ant-checkbox-inner,
.recording-check-box:hover > .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: var(--hs-violet) !important;
}
.recording-check-box .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--hs-violet);
  box-shadow: none;
}
.recording-check-box .ant-checkbox .ant-checkbox-inner {
  box-sizing: border-box;
  position: relative;
  top: 0;
  inset-inline-start: 0;
  display: block;
  width: 18px;
  height: 18px;
  direction: ltr;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}

/* .recording-check-box.ant-checkbox .ant-checkbox-inner:after {
  box-sizing: border-box;
  position: absolute;
  top: 47%;
  inset-inline-start: 21.5%;
  display: table;
  width: 5.714286px;
  height: 10.142857px;
  border: 2px solid #fff !important;
  border-top: 0;
  border-inline-start: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  content: "";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
} */
