.center {
  margin: 10%;
  width: 80%;
  padding: 10px;
  height: 50%;
}

.padding {
  padding: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  /* grid-template-rows: repeat(4, auto); */
  gap: 10px;
  padding-left: 0px;
}

.grid-item {
  display: flex;
  align-items: center;
}

.grid-item label {
  /* margin-right: 10px; */
  margin-left: 100px;
}

.grid-item input {
  width: 100%;
}

.divContainer {
  padding-top: 15px !important;
  display: inline-flex;
  /* grid-template-columns: 1fr; */
  /* gap: 10px; */
  padding-right: 100px;
}

.divContainer div {
  margin-top: 10px !important;
  margin-left: 50px;
}

.toolsCard .ant-card-head {
  background: #fafafa;
  font-weight: 600;
}
