.time-zone-select .css-t3ipsp-control:hover {
  border: 2px solid var(--hs-violet) !important;
  border-color: var(--hs-violet) !important;
}
.time-zone-select .css-t3ipsp-control {
  box-shadow: none !important;
}
/*  selected */
.time-zone-select .css-tr4s17-option {
  background-color: var(--hs-violet) !important;
}
.time-zone-select .css-10wo9uf-option:hover {
  background-color: none !important;
}
.bh-timer-picker.ant-picker-outlined:focus,
.bh-timer-picker.ant-picker-outlined:focus-within {
  border-color: var(--hs-violet) !important;
  border: 1.5px solid;
  box-shadow: none;
}
.bh-timer-picker.ant-picker-outlined:hover {
  border-color: var(--hs-violet) !important;
}
