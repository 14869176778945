.tenDlcMenu.ant-steps.ant-steps-small .ant-steps-item-icon {
  border-radius: 4px;
  background-color: green;
  border-color: green;
}
.tenDlcMenu.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.tenDlcMenu.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon,
.tenDlcMenu.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: green;
  border-color: green;
}
.tenDlcMenu.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: grey !important;
}
.tenDlcMenu.ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: white;
}
.tenDlcMenu.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #8bbd7f;
}

.tenDlcMenu.ant-steps.ant-steps-navigation
  .ant-steps-item.ant-steps-item-active::before,
.tenDlcMenu.ant-steps.ant-steps-navigation .ant-steps-item::before {
  background-color: green;
}
.description10 {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: rgb(96, 107, 133);
}
.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
}
.saveBtn {
  border-radius: 4px;
  background-color: green;
  color: aliceblue;
}
.radioDescription {
  color: grey;
  font-size: 12px;
}
.registerA2pForm .ant-form-item {
  margin-bottom: 0px;
  margin-top: 20px;
}

.casesSelect {
  color: #334d6e !important;
  font-size: 1rem;
}
.description14 {
  font-size: 14px;
  color: green;
}
.tenDlcMenu .anticon svg {
  color: whitesmoke;
  vertical-align: baseline;
}
.tendlcAlert {
  margin-top: 2.5rem;
}
.seeMoreExpandable {
  cursor: pointer;
  color: #009efd;
}
.seeMoreExpandable:hover {
  opacity: 50%;
  transition: 0.5s;
}
.msgServiceSelect .ant-select-selection-item {
  height: inherit;
}
.cases-select .ant-select-selection-item {
  height: inherit;
}
