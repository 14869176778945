@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Raleway:wght@300&family=Rubik&display=swap');
.loginContainer{
    font-family: 'Rubik', sans-serif !important;
}

.welcomeTitle{
    font-family: 'Raleway', sans-serif;
}

.loginInfo{
    font-family: 'Quicksand', sans-serif;

}