.voiceButton {
  background-color: #5551ff;
  border: none;
  color: white;
  border-radius: 10px;
  height: 50px;
}
.onlineIndicatorDot {
  width: 10px;
  height: 10px;
  background-color: #35c658;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.call-close-icon {
  background-position: 41% 72%;
}
.border-bottom-dark-grey {
  border-bottom: 1px solid #374151;
}

.busy-indicator-text {
  color: orange;
}

.offline-indicator-text {
  color: rgb(178, 176, 176);
}
.busyIndicatorDot {
  width: 10px;
  height: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.offlineIndicatorDot {
  width: 10px;
  height: 10px;
  background-color: rgb(178, 176, 176);
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.callEndIcon {
  background-image: url("../icons/Action\ icons.svg");
  height: 72px;
  width: 72px;
  background-position: 51.5% 23.2%;
  cursor: pointer;
  background-size: 900px 900px;
}
.call-end-icon-in-answered-screen {
  background-image: url("../icons/Action\ icons.svg");
  height: 50px;
  width: 50px;
  background-position: 51.5% 23.2%;
  cursor: pointer;
  background-size: 600px 600px;
}
.call-action-icons {
  background-image: url("../icons/Action\ icons.svg");
  height: 25px;
  width: 25px;
}
.helloSendIcons {
  background-image: url("../icons/helloSendIcons.png");
  height: 25px;
  width: 25px;
}
.callCardIcons {
  background-image: url("../icons/Cards.svg");
  width: 180px;
  height: 180px;
}
