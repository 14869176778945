.inputAreaSize{
    width: 300px !important;
    height: 100% !important;
    border-color: #D6DDE6;
  }
  .generatedInputBorder{
    border: 0px;
    border-color: #D6DDE6;

  }
  .cardPara .ant-card-body{
    padding: 10px !important;
    height: 85% !important;
  }
  .generateBtn{
    font-size: 12px !important;
  }
  .no-credits-notification{
    background-color: #464A52;
    color: #fff !important;
  }
  .custom-notification-class{
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff !important;
  }

  .custom-notification-class .ant-notification-notice-message , .no-credits-notification .ant-notification-notice-message{
    color: #fff !important;
  }
  .custom-notification-class .anticon-close svg , .no-credits-notification .anticon-close svg{
    color: #fff !important;
  }
  .disabled-class-color{
    color: #bcbcbc !important;
  }
  .creditsCardClass {
    background-color: #464a52;
  }
  .creditLowBalanceClass {
    background-color: #cb453f;
  }
  .upgradeButton{
    font-size: 10px !important;
    color: #529bf8;
    font-weight: bold;
  }
  .creditLowBalanceClass .upgradeButton{
    color: #cb453f;
  }

  .creditLowBalanceClass .ant-card-body, .creditsCardClass .ant-card-body{
    padding: 0.25rem !important;
    padding-right: 1.5rem !important;
    padding-left: 1rem !important
  }
  .creditLowBalanceClass .ant-space-vertical,.creditsCardClass .ant-space-vertical{
    gap: 0px !important;
  }

  .overlay{
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background: rgb(120, 120, 120);
    opacity: .5;
    z-index: 1000;
}
