body {
  font-family: "Inter", sans-serif, sans-serif;
}

.savedUserTable {
  width: 82rem;

  border-radius: 8px 8px 0 0;

  margin-top: 1rem;
}

.savedUserTable.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #d0d0d0;
}

.savedUserTable thead .ant-table-cell {
  background-color: #009efd !important;
  color: white !important;
  line-height: 1;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}

.savedUserTable .ant-table-wrapper table {
  border-left: #d0d0d0 solid 1px;
  border-right: #d0d0d0 solid 1px;
}

.usersEmail .ant-alert {
  width: fit-content;
}

.saveduserTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.savedUserTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}

#addUserBtn {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  /* margin-top: 2rem; */

  border: none;
  background-color: #f9f9fb;
  color: #009efd;
}
#emailInput {
  width: 13rem;
  height: 2.5rem;
}
#email {
  font-family: "Inter", sans-serif;
}
.usersAddButton {
  text-align: end;
}
.usersAddButton.ant-btn.ant-btn-lg {
  border-radius: 4px;
}
#usersAdd {
  background-color: #109cf1;
  width: 5rem;
  height: 2.5rem;
}
#fdnameError {
  color: #ee3248;
  font-size: 10px;
}
#friendlyNameId {
  font-size: 10px;
}
.selectUser {
  width: 100% !important;
  min-height: 3.5rem !important;
}

.usersEmail {
  white-space: pre-line;
}

.userDrawer .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}
.deleteUserIcon {
  background: url("../images/icons.png") -239px -235.9px;
}

.deleteUserIcon:hover {
  background: url("../images/icons.png") -111px -232.9px;
}
.searchBoxInSelectComponent {
  border-bottom: 1px solid #d0d0d0 !important;
  height: 50px;
  border-radius: 0px;
}
.selectUser .ant-select-selection-item {
  font-weight: 500 !important;
}

.user-select-tab {
  border: none !important;
}

.user-select-tab .anticon-close {
  font-size: larger;
  color: black;
}
.users-number-list {
  min-height: 25px !important;
  min-width: 25px;
  display: inline-block;
  vertical-align: middle;
}

.users-number-list-ringcentral {
  background-position: -29.65rem -5.41rem;
}

.users-number-list-twilio {
  background-position: -36.876rem -5.425rem;
}
.users-number-list-hello_send {
  background-position: -29.65rem -7.21rem;
}
.users-number-list-whatsapp {
  background-position: -31.45rem -7.01rem;
}
.assign-phonenumbers {
  background-color: #f3f4f6 !important;
}
.roles-based-number {
  max-height: 15%;
  overflow-y: auto;
}
.users-configuration-collapse.ant-collapse .ant-collapse-content {
  background-color: #f8f7ff !important;
  border-left: 3px solid var(--hs-violet) !important;
}
.users-configuration-collapse .ant-collapse-content {
  border-radius: 8px !important;
}
.add-user-drawer-messaging-icon {
  background-position: 85% 7%;
  background-size: 650px 650px;
}
.add-user-drawer-voice-icon {
  background-position: 85% 2%;
  background-size: 650px 650px;
}
.users-configuration-collapse .ant-collapse-item > .ant-collapse-header {
  padding: 15px 0px;
}
.users-voice-permission-select .ant-select-selector {
  background-color: inherit !important;
}
.users-voice-permission-select .ant-select-selection-placeholder {
  font-size: 12px !important;
}

.users-voice-permission-select .ant-select-selector {
  font-size: 12px !important;
}
.users-voice-permission-select {
  width: 100px !important;
}
.users-selectedTeams-list .ant-list-item-action-split {
  display: none;
}
.users-selectedTeams-list.ant-list-split .ant-list-item {
  border: none !important;
  padding: 5px 0;
}
.users-selectedTeams-list .ant-spin-container {
  max-height: 212px !important;
  overflow: auto;
}
.hs-tag-item.ant-tag {
  padding-inline: 16px;
  border-radius: 15px;
  border: 1px solid var(--hs-light-silver);
}
.users-tag-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 125px;
}
