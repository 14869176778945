.ringingPadContainer {
  width: 400px;
  height: 700px;
  background-color: #1c1f30;
  border-radius: 14px;
}
.downToUp {
  transform: translateX(-50%) translateY(0);
}

body {
}

.wrapper {
  position: relative;
  width: 55px;
  height: 55px;
}
.wrapper .circle {
  width: 55px !important;
  height: 55px !important;
  position: absolute;
  border-radius: 50%;
  transform: scale(1, 1);
  background-color: #3c3c3c;
}
.call-ringing-screen .wrapper {
  width: 70px !important;
  height: 70px !important;
}
.call-ringing-screen .wrapper .circle {
  width: 90px !important;
  height: 90px !important;
}
.wrapper .circle.pulse {
  animation: pulse 2s infinite;
  background-color: #3c3c3c;
  opacity: 0.4;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.callDescriptionIcon {
  background-position: 80% 2.5%;
  background-size: 600px 600px;
}
.recordingsPlayIcon {
  background-position: 22% 11.5%;
  background-size: 600px 600px;
}

.audio-player.ant-modal .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.missedCallIcon {
  background-position: 48.2% 3%;
}
.audio-player.ant-modal {
  top: 380px;
}
.emptyVoiceCallListView {
  background-position: -4% -8%;
}
.callHistoryList.ant-list-split .ant-list-item {
  border-block-end: 2px solid var(--hs-dark-grey);
}
.history-time {
  flex: 0 0 auto;
  width: 21%;
}
.callDirection-inbound {
  background-position: 84% 9%;
  background-size: 900px 900px;
}
.callDirection-outbound {
  background-position: 80.3% 9%;
  background-size: 900px 900px;
}
.audio-player .ant-modal-close:hover {
  color: black;
  background-color: transparent;
  /* transition: color 0.2s, background-color 0.2s; */
}
.contact-item {
  position: relative;
}

.logs-clickToCall-Icon {
  background-position: 22% 7.3%;
  height: 20px !important;
  background-size: 600px 600px;
}

.contact-item .call-icon {
  /* position: absolute;
  right: 44%;
  top: 30%;
  transform: translateY(-50%); */
  cursor: pointer;
  display: none;
}

.contact-item:hover .call-icon {
  display: block;
}
.call-history-item {
  position: relative;
}

.call-title-container {
  display: flex;
  align-items: center;
  position: relative;
}

.call-title-container .call-icon {
  display: none;
}

.call-title-container:hover .call-icon {
  display: inline-block;
}
.call-history-item .ant-list-item-meta-avatar {
  margin-inline-end: 9px !important;
}
.callHistoryList .ant-spin-container {
  height: 335px;
}
.call-history-callRecord-icon {
  background-position: 90% 35%;
  height: 16px !important;
  width: 15px !important;
}
.empty-call-history-icon {
  background-position: 5% 73%;
  height: 73px !important;
  width: 65px !important;
}
.call-history-callNote-icon {
  background-position: 94% 35%;
  height: 16px !important;
  width: 18px !important;
}
.call-details-icon {
  background-position: 200% 25.6%;
  height: 16px !important;
  width: 18px !important;
}

.create-record-plus-icon {
  width: 18px !important;
  background-position: 21% 71.8%;
}

.create-record-dropdown-icon {
  width: 18px !important;
  background-position: 24.5% 71.7%;
}

.create-record-link-parent .ant-dropdown-menu {
  background-color: #2a2a3c !important;
}

.create-record-link-parent .ant-dropdown-menu-item {
  color: white !important; /* Optional: for text visibility */
  border-bottom: 1px solid #374151;
}
.call-logs-menu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: var(--hs-violet);
}
.call-logs-menu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-bottom-width: 2px;
  border-bottom-color: var(--hs-violet);
}
.callHistoryList .ant-spin-nested-loading > div > .ant-spin {
  background: #1d2031;
}

.call-logs-menu.ant-menu-horizontal {
  border-bottom: none !important;
}
